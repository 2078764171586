// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-pages-admin-index-tsx": () => import("./../src/components/pages/admin/index.tsx" /* webpackChunkName: "component---src-components-pages-admin-index-tsx" */),
  "component---src-components-pages-collection-index-tsx": () => import("./../src/components/pages/collection/index.tsx" /* webpackChunkName: "component---src-components-pages-collection-index-tsx" */),
  "component---src-components-pages-collections-index-tsx": () => import("./../src/components/pages/collections/index.tsx" /* webpackChunkName: "component---src-components-pages-collections-index-tsx" */),
  "component---src-components-pages-error-404-index-tsx": () => import("./../src/components/pages/error/404/index.tsx" /* webpackChunkName: "component---src-components-pages-error-404-index-tsx" */),
  "component---src-components-pages-error-500-index-tsx": () => import("./../src/components/pages/error/500/index.tsx" /* webpackChunkName: "component---src-components-pages-error-500-index-tsx" */),
  "component---src-components-pages-home-index-tsx": () => import("./../src/components/pages/home/index.tsx" /* webpackChunkName: "component---src-components-pages-home-index-tsx" */),
  "component---src-components-pages-model-index-tsx": () => import("./../src/components/pages/model/index.tsx" /* webpackChunkName: "component---src-components-pages-model-index-tsx" */),
  "component---src-components-pages-order-index-tsx": () => import("./../src/components/pages/order/index.tsx" /* webpackChunkName: "component---src-components-pages-order-index-tsx" */),
  "component---src-components-pages-sitemap-index-tsx": () => import("./../src/components/pages/sitemap/index.tsx" /* webpackChunkName: "component---src-components-pages-sitemap-index-tsx" */)
}

